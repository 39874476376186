import React from "react";
import appStoreImg from "../img/appStore.png";
import googlePlayImg from "../img/googlePlay.png";
import logoWhite from "../img/mrLewisWhite.png";
import ArrowButton from "../Components/ArrowButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bottom-0 w-full">
      <div className="bookroom bg-darkgreen w-full p-20">
        <h1 className="cursor-pointer distributor-open text-white text-center sm:text-5xl">
          Book Room <ArrowButton color="#ffffff"></ArrowButton>
        </h1>
      </div>
      <div className="bookroom bg-darkgreen opacity-75 w-full sm:p-32 p-5">
        <div className="sm:flex mb-4">
          <div className="sm:w-3/6 ">
            <h1 className="text-white tracking-widest"> LET'S KEEP IN TOUCH</h1>
            <form className="w-full max-w-sm">
              <div className="flex items-center border-b border-white py-2">
                <input
                  className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none color-white"
                  type="text"
                  placeholder="Your e-mail.."
                  aria-label="Full name"
                />
                <button
                  className="flex-shrink-0 border-transparent border-4 text-white hover:text-teal-800 text-sm py-1 px-2 rounded"
                  type="button"
                >
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                </button>
              </div>
            </form>
          </div>
          <div className="sm:w-1/6 mt-5 ">
            <ul>
              <Link to="/">
                <li className="text-gray-300 py-2 text-xs tracking-widest">
                  Home{" "}
                </li>
              </Link>
              <Link to="/about">
                <li className="text-gray-300 py-2 text-xs tracking-widest">
                  About us{" "}
                </li>
              </Link>
              {/* <Link to="/overveen">
                <li className="text-gray-300 py-2 text-xs tracking-widest">
                  Overveen{" "}
                </li>
              </Link> */}
              <Link to="/rotterdam">
                <li className="text-gray-300 py-2 text-xs tracking-widest">
                  Rotterdam{" "}
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="text-gray-300 py-2 text-xs tracking-widest">
                  Contact{" "}
                </li>
              </Link>
            </ul>
          </div>
          <div className="sm:w-1/6 mt-5 ">
            <ul>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                FAQ
              </li>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                Terms of use
              </li>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                Privacy Statement
              </li>
            </ul>
          </div>
          <div className="sm:w-1/6 mt-5 ">
            {/* <p>Overveen:</p> */}
            {/* <ul>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                <a href="mailto:zeyravof@gmail.com">zeyravof@gmail.com</a>
              </li>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
              +31 (0) 6 87 86 02 85
              </li>
            </ul> */}
            {/* <br /> */}
            <p>Rotterdam:</p>
            <ul>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                <a href="mailto:stay@mrlewis.n">stay@mrlewis.n</a>
              </li>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
              +31 (0) 103 10 51 53
              </li>
            </ul>
          </div>
          <div className="sm:w-1/6 mt-5 ">
            <ul>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                <a href="https://www.facebook.com/mr.lewishotel/">Facebook</a>
              </li>
              <li className="text-gray-300 py-2 text-xs tracking-widest">
                <a href="https://www.instagram.com/mr.lewishotel/">Instagram</a>
              </li>
              {/* <li className="text-gray-300 py-2 text-xs tracking-widest">
                Twitter
              </li> */}
            </ul>
          </div>
        </div>
        <div className="sm:flex mb-4">
          <div className="sm:w-1/2 mt-20 ml-auto">
            <h1 className="text-white tracking-widest mb-6"> DOOR KEY </h1>
            <div className="flex">
              <a href="https://apps.apple.com/us/app/justin-mobile/id960998088">
                {" "}
                <img alt="" src={appStoreImg} className="w-40"></img>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.saltosystems.justin&hl=nl&gl=US">
                <img alt="" src={googlePlayImg} className="w-40"></img>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex tracking-wide bg-darkgreen text-left text-xs p-3 absolute bottom-0 w-full text-white ">
        <img alt="" src={logoWhite} className="h-6 m-0 p-0 ml-40"></img>
        <span className="ml-10 text-small">
          <h1 className="text-white tracking-widest">
            {" "}
            &copy; Mr.Lewis 2020 | All rights reserved
          </h1>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
