import React from "react";
import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function TestemonialCard(props) {
  let content = null;
  const testemonials = [
    {
      name: "Suzanne Vreeswijk-Renckens",
      createdAt: "Jan 2021",
      review:
        "Lovely hotel! It’s close to the beach and to Haarlem’s city centre. The room’s design is very nice and all necessary amenities are available.",
    },
    {
      name: "Timo Haaker",
      createdAt: "Aug 2020",
      review:
        "Our stay at Mr.Lewis hotel was great! The check in was very easy with the app and we loved the designed room. The bed was very comfortable! Would definitely come again. 10/10",
    },
    {
      name: "Britt Duinker",
      createdAt: "May 2020",
      review:
        "The rooms are beautiful! Stylish and trendy furnished. Mr Lewis is located perfectly, close to: the beach Zandvoort and Bloemendaal, the ( nightlife) centre of Haarlem, the beautiful forest and the train station Overveen. And what a lovely beds. Definitely going back!",
    },
  ];
  const customRenderItem = (clickHandler, isSelected, index, label) => {
    // if (isSelected !== true) {
    //   return (
    //     <div className="flex">
    //       <h1 className="text-4xl text-gray-400"> &nbsp; 0{index + 1} </h1>
    //     </div>
    //   );
    // }
    // if (isSelected === true) {
    //   return (
    //     <div className="flex">
    //       <h1 className="text-4xl text-darkgreen"> &nbsp; 0{index + 1} </h1>
    //       <svg
    //         width="62"
    //         height="55"
    //         viewBox="0 0 62 2"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <rect width="62" height="2" fill="#344146" />
    //       </svg>
    //     </div>
    //   );
    // }
  };
  if (testemonials) {
    content = testemonials.map((data, key) => (
      <div key={key} className="myCarousel flex mt-10 bg-beige-t-50">
        <div className="sm:w-2/6 ml-auto sm:mr-64 mb-5 ">
          <h3 className="text-xl py-5 text-left">
            {data.name} | {data.createdAt}
          </h3>
          <p className="text-darkgreen text-left">{data.review}</p>
        </div>
      </div>
    ));
  }
  return (
    <div className="">
      <div className="flex mt-20">
        <div className="w-4/5 ml-auto mr-auto">
          <h1 className="text-5xl darkgreen">What guests say about us? </h1>
        </div>
      </div>
      <Carousel
        // showArrows={true}
        infiniteLoop={true}
        // showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={10000}
        renderIndicator={customRenderItem}
      >
        {content}
      </Carousel>
    </div>
  );
}

export default TestemonialCard;
