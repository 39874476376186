import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Logo from "../img/logo_lewis.png";
import Notification from "./Notification";

function Header() {
  const [index, set] = useState(0);

  // const [isLogoActive, setLogoActive] = useState(true);

  useEffect(() => {
    var scrollpos = window.scrollY;
    document.addEventListener("scroll", function () {
      scrollpos = window.scrollY;
      if (scrollpos > 100) {
        set(1);
      } else {
        set(0);
      }
    });
  });

  return (
    <nav
      className={
        "fixed w-full z-30 top-0 text-white backgroundMenu " +
        (index
          ? "bg-beige-t-50 transition duration-500 shadow-md"
          : "bg-transparant transition duration-500")
      }
    >
      <Notification />
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <div className="toggleColour text-red-500 no-underline hover:no-underline font-bold text-5xl lg:text-4xl">
            <img src={Logo} alt="logoMrLewis" width="50px"></img>
          </div>
        </div>
        <Navigation />
      </div>
    </nav>
  );
}

export default Header;
