import React from "react";
import HeaderTagLine from "../Components/HeaderTagLine";
import FullImgBlock from "../Components/FullImgBlock";
import ContentBlockMainPage from "../Components/ContentBlockMainPage";
import AboutUs from "../img/aboutus.png";
import AboutUs1 from "../img/aboutus1.png";
import AboutUs2 from "../img/aboutus2.png";
import MRLQuote from "../Components/MRLQuote";

function About() {
  return (
    <div className="mt-40">
      <HeaderTagLine
        title="Innovative Hospitality"
        desc="Check-in and check-out is a breeze, no lines, no waiting, you are the boss of your schedule. Mr. Lewis is a boutique SELF-SERVICE hotel designed so a guest can check-in and out with just a phone. All you need is to download the Just-in app which will give you the key to your room. If you plan to stay longer, just book another night and your access to the front entrance and room will updated. We hope you will enjoy your stay."
        readmore={false}
        readmoreLink="#"
        downloadapp={true}
      ></HeaderTagLine>
      <FullImgBlock img={AboutUs}></FullImgBlock>
      <MRLQuote text="Mr. Lewis says RELAX and ENJOY the new SEAMLESS experience"></MRLQuote>
      <ContentBlockMainPage
        locName="DAB Studio"
        link={true}
        img={AboutUs1}
        locDesc={[
          "Mr. Lewis was happy to partner with",
          <a href="www.dabstudio.nl" className="underline">
            {" "}
            Dab Studio{" "}
          </a>,
          ", an Amsterdam based multi-disciplinary interior design studio, established in 2016. They are known to specialize in design in a range of different spaces, spanning from private residential, commercial, corporate and hospitality. Their guiding values of simplicity, timelessness and attention to craft and detail sets them apart from your everyday designer. We are pleased to have them as our partners having created a peaceful and comfortable experience for our guests.",
        ]}
        button={false}
        mirrored={false}
        bgColor="bg-babyred"
      ></ContentBlockMainPage>
      <ContentBlockMainPage
        locName="Self-service design shapes true privacy"
        img={AboutUs2}
        locDesc={[
          // "Devising a hotel experience where the guest has no in-person contact with hotel personnel allows for honest privacy. A guest can always contact the hotel operator through the phone, we use ",
          // <a href="whatsapp://send?phone=31686830448" className="underline">
          //   {" "}
          //   Whatsapp{" "}
          // </a>,
          // "to make the communication process more convenient. You can book a bicycle trip and have all your questions answered through this easy means of correspondence.",
          "Devising a hotel experience where the guest has no in-person contact with hotel personnel allows for honest privacy. A guest can always contact the host by phone. Please make a call to ",
          <a href="tel:+311031051 53" className="underline">
            {" "}
            +31 (0) 10 310 51 53{" "}
          </a>,
          " if you have any questions or require assistance.",
        ]}
        button={false}
        mirrored={true}
        bgColor="bg-babyred"
      ></ContentBlockMainPage>
    </div>
  );
}
export default About;
