import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function NavigationMenu(props) {
  return (
    <div>
      <div className="fixed bg-beige-t-50 top-0 left-0 w-full h-full z-50 shadow p-3 flex h-screen">
        {/* COL 6 met Rotterdam erbij */}
        <ul className="flex flex-col md:flex-row m-auto z-100">
          <li className="col-span-2 p-6 text-center sm:border-r-2 sm:border-black">
            <Link
              to="/"
              className="text-4xl inline-block text-black block"
              onClick={props.closeMenu}
            >
              Home
            </Link>
          </li>
          <li className="col-span-2 p-6 text-center sm:border-r-2 sm:border-black">
            <Link
              to="/about"
              className="text-4xl inline-block text-black block"
              onClick={props.closeMenu}
            >
              About us
            </Link>
          </li>
          {/* <li className="col-span-2 p-6  text-center sm:border-r-2 sm:border-black">
            <Link
              to="/overveen"
              className="text-4xl inline-block text-black block"
              onClick={props.closeMenu}
            >
              Overveen
            </Link>
          </li> */}
          <li className="col-span-3 p-6  text-center border-r-2 border-black">
            <Link
              to="/rotterdam"
              className="text-4xl inline-block text-black block"
              onClick={props.closeMenu}
            >
              Rotterdam
            </Link>
          </li>
          <li className="col-span-2  p-6 text-center">
            <Link
              to="/contact-us"
              className="text-4xl inline-block text-black block"
              onClick={props.closeMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
        <span className="text-2xl text-black mr-16 ">
          <FontAwesomeIcon icon={faTimes} onClick={props.closeMenu} />
        </span>
      </div>
    </div>
  );
}

export default NavigationMenu;
