import React from "react";
import ArrowButton from "../Components/ArrowButton";

function HeaderTagLine(props) {
  let content = null;
  if (props.readmore) {
    content = (
      <ArrowButton color="#344146" text="READ MORE">
        {" "}
      </ArrowButton>
    );
  }
  if (props.downloadapp) {
    content = (
      <div>
        <br />
        Download App -{" "}
        <a href="https://apps.apple.com/us/app/justin-mobile/id960998088">
          {" "}
          Apple |
        </a>{" "}
        <a href="https://apps.apple.com/us/app/justin-mobile/id960998088">
          {" "}
          Android
        </a>
      </div>
    );
  }
  return (
    <div>
      <div className="flex mt-20">
        <div className="w-3/5 ml-auto mr-auto">
          <h1 className="text-3xl darkgreen font-medium tracking-wider">
            {props.title}
          </h1>
        </div>
      </div>
      <div className="flex mt-10 m-5">
        <div className="sm:w-2/6 ml-auto sm:mr-64 mb-20">
          <p className="text-darkgreen">{props.desc} </p>
          {content}
          <p className="mt-5 font-black text-darkgreen"></p>
        </div>
      </div>
    </div>
  );
}

export default HeaderTagLine;
