import React from "react";

function ArrowButton(props) {
  return (
    <span>
      {props.text}
      <button
        className="flex-shrink-0 border-transparent border-4 text-white hover:text-teal-800 text-sm py-1 px-2 rounded"
        type="button"
      >
        <svg
          width="64"
          height="14"
          viewBox="0 0 64 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="6.5"
            width="61"
            height="1"
            fill="#FAF7F1"
            stroke={props.color}
          />
          <path d="M56 1L62 7L56 13" stroke={props.color} strokeWidth="2" />
        </svg>
      </button>
    </span>
  );
}
export default ArrowButton;
