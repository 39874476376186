import React from "react";

function HeaderBlock(props) {
  return (
    <div className="">
      <div className="relative inline-block ">
        <img
          src={props.img}
          alt="headerBlock"
          className="top-0 left-0 opacity-50 block minHmobiel"
        ></img>
        {/* <div className="absolute textBlockImage flex-wrap mb-4">
          <h1 className="text-2xl text-center text-darkgreen w-1/2 ml-auto mr-auto">
            {props.text}
          </h1>
        </div> */}
        <div className="absolute textBlockImage inset-x-0 flex justify-center mb-4">
          <h1 className="lg:text-2xl md:text-xl sm:text-base text-center text-darkgreen sm:w-1/2 py-4 px-16">
            {props.text}
          </h1>
        </div>
        <div className="absolute textBlockButton inset-x-0 top-2/4 flex justify-center">
          <button className="distributor-open bg-darkgreen hover:bg-darkgreen text-white py-4 px-16">
            BOOK NOW
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeaderBlock;
