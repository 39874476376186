import React from "react";

function FullImgBlock(props) {
  return (
    <div className="w-full">
      <img alt="" src={props.img}></img>
    </div>
  );
}

export default FullImgBlock;
