import React from "react";
// import Loader from "../Components/Loader";
import { useAxiosGet } from "../Hooks/HttpRequests";
import HeaderBlock from "../Components/HeaderBlock";
import HeaderTagLine from "../Components/HeaderTagLine";
import ContentBlockMainPage from "../Components/ContentBlockMainPage";
import TestemonialCard from "../Components/TestemonialCard";
import headerImg from "../img/background.png";
import OverveenImg from "../img/OverveenImg.png";
import RotterdamImg from "../img/Rotterdam/superKing2.jpg";

function Home() {
  // let content = null;

  const url = `https://5fa538db732de900162e8e61.mockapi.io/api/v1/testemonials`;
  let testemonials = useAxiosGet(url);
  // if (testemonials.loading) {
  //   content = <Loader> </Loader>;
  // }
  // if (testemonials.error) {
  //   content = (
  //     <div>
  //       <div className="bg-red-300 p-3">
  //         There was an error please refresh or try again later.
  //       </div>
  //     </div>
  //   );
  // }
  // if (testemonials.data) {
  //   content = testemonials.data.map((data, key) => (
  //     <div key={data.id}>
  //       <TestemonialCard product={data} />
  //     </div>
  //   ));
  // }

  return (
    <div>
      <HeaderBlock
        text="RELAX and ENJOY the new SEAMLESS experience"
        img={headerImg}
      ></HeaderBlock>
      <HeaderTagLine
        title="A New Age for Hotels"
        desc="Mr. Lewis is a boutique self-service hotel designed to create a smooth and seamless
        stay. A guest can check-in and out with just a phone. With free internet in every room, the guest has access to live tv, games and Netflix at the touch of their hands. Mr. Lewis also has bicycles for hire, where one can explore the neighborhood and discover the many activities that are available in the area."
        readmoreLink=""
      ></HeaderTagLine>
      {/* COL 1 */}
      <ContentBlockMainPage
        locName="Hotel Rotterdam"
        img={RotterdamImg}
        locDesc="Mr.Lewis Hotel Rotterdam established in a
                  beautiful mansion at the heemraadssingel in the centre
                  of Rotterdam. Everything in Rotterdam is just minutes
                  away. Ahoy(Link naar de Ahoy website) is the place to
                  be for all your events and easily reachable with public
                  transport. The hotel has a beautiful view across the
                  canal and park view just in front of the hotel. As it is a
                  typical city hotel, there are a lot of places to grab some
                  breakfast or have a nice diner. Come have a look and
                  we hope to have the pleasure to host you soon!"
        buttonText="CHECK OUT HOTEL ROOMS"
        buttonLink="/rotterdam"
        mirrored={true}
        bgColor="bg-baby-blue"
        button={true}
        key="rotterdam"
      ></ContentBlockMainPage>
      {/* <ContentBlockMainPage
        locName="Hotel Overveen"
        img={OverveenImg}
        locDesc="Enjoy a much-deserved vacation from the demands of the every-day, grab a pizza from the best pizza bar in town, and hire a bicycle to explore the area. Mr. Lewis hotel Overveen is always looking forward to welcome new guests at his boutique hotel. The brand-new hotel opened doors late 2018 and has four beautiful rooms designed by DAB Design Amsterdam. The comfortable rooms provide you with a private, and quality stay. The location is perfect for outdoor activities or just an easy-going and private city trip stay.."
        buttonText="CHECK OUT HOTEL ROOMS"
        buttonLink="/overveen"
        mirrored={false}
        bgColor="bg-baby-blue"
        button={true}
        key="overveen"
      ></ContentBlockMainPage> */}
      {/* EIND COL 1 */}
      {/* COL 2 */}
      {/* EIND COL 2 */}
      {/* {content} */}
      {/* <Testemonial title="A NEW AGE FOR HOTELS"></HeaderTagLine> */}
      <TestemonialCard product={testemonials} />
      {/* {this.testemonials.data.map((product, key) => (
        <div>As </div>
      ))} */}
    </div>
  );
}
export default Home;
