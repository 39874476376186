import React, { useState } from "react";
import HeaderBlock from "../Components/HeaderBlock";
import RotterdamImg from "../img/Rotterdam/Rotterdam-img.jpg";
import headerImg from "../img/OverveenHeader.png"

import superKing1 from "../img/Rotterdam/superKing1.jpg";
import superKing2 from "../img/Rotterdam/superKing2.jpg";
import superKing3 from "../img/Rotterdam/superKing3.jpg";

import juniorSuite1 from "../img/Rotterdam/juniorSuite1.jpg";
import juniorSuite2 from "../img/Rotterdam/juniorSuite2.jpg";
import juniorSuite3 from "../img/Rotterdam/juniorSuite3.jpg";

import juniorStudio1 from "../img/Rotterdam/juniorStudio1.jpg";
import juniorStudio2 from "../img/Rotterdam/juniorStudio2.jpg";
import juniorStudio3 from "../img/Rotterdam/juniorStudio3.jpg";

import standardDouble1 from "../img/Rotterdam/standardDouble1.jpg";
import standardDouble2 from "../img/Rotterdam/standardDouble2.jpg";
import standardDouble3 from "../img/Rotterdam/standardDouble3.jpg";

import smallStandardDouble1 from "../img/Rotterdam/smallStandardDouble1.jpg";
import smallStandardDouble2 from "../img/Rotterdam/smallStandardDouble2.jpg";
import smallStandardDouble3 from "../img/Rotterdam/smallStandardDouble3.jpg";

import iconInfo from "../img/buttonMoreInfo.png";
import ContentBlockMainPage from "../Components/ContentBlockMainPage";

function Home() {
  const [rooms, setRooms] = useState([
    {
      room: "Superior King Suite",
      headImage: superKing1,
      headDescription:
        "The Superior King Suite is a suite of 25m2. This room is at the ground floor with a large desk and a beautiful view to our street side with the fountain in the canal and the beautiful green large trees.",
      people: 2,
      bedrooms: 1,
      beds: "1 large King size bed 1,8m wide",
      bathroom: 1,
      coffee: "Nespresso Coffee",
      minibar: true,
      safe: true,
      ac: true,
      tv: "Smart TV with cable TV and streaming options",
      wifi: "Free and unlimited high speed WIFI",
      miniImage1: superKing1,
      miniImage2: superKing2,
      miniImage3: superKing3,
    },
    {
      room: "Junior Suite",
      headImage: juniorSuite1,
      headDescription:
        "Our junior suites are perfect for couples and business travellers. It is suitable for longer stays. The 20m2 size room is equipped with a queen size bed of 1,6m wide. The rooms are at the street side as of the garden side of the property. Both are gorgeous views.",
      people: 2,
      bedrooms: 1,
      beds: "1 large Queen size bed 1,6m wide",
      bathroom: 1,
      coffee: "Nespresso Coffee",
      minibar: true,
      safe: true,
      ac: true,
      tv: "Smart TV with cable TV and streaming options",
      wifi: "Free and unlimited high speed WIFI",
      miniImage1: juniorSuite1,
      miniImage2: juniorSuite2,
      miniImage3: juniorSuite3,
    },

    {
      room: "Junior Studio suite",
      headImage: juniorStudio1,
      headDescription:
        "Our Junior Studio Suit is a suite of approx. 25m2. This room is on the second floor and is perfect for longer stay holidays.",
      people: 2,
      bedrooms: 1,
      beds: "1 large Queensize bed 1,6m wide",
      bathroom: 1,
      coffee: "Nespresso Coffee",
      minibar: true,
      safe: true,
      ac: true,
      tv: "Smart TV with cable TV and streaming options",
      wifi: "Free and unlimited high speed WIFI",
      miniImage1: juniorStudio1,
      miniImage2: juniorStudio2,
      miniImage3: juniorStudio3,
    },
    {
      room: "Standard Double Room",
      headImage: standardDouble1,
      headDescription:
        "Our Standard Double rooms are 15m2 rooms. Perfect for short stays. Whenever if you are a couple or single traveller. The perfect place for city hoppers.",
      people: 2,
      bedrooms: 1,
      beds: "1 large Queen size bed 1,6m wide",
      bathroom: 1,
      coffee: "Nespresso Coffee",
      minibar: true,
      safe: true,
      ac: true,
      tv: "Smart TV with cable TV and streaming options",
      wifi: "Free and unlimited high speed WIFI",
      miniImage1: standardDouble1,
      miniImage2: standardDouble2,
      miniImage3: standardDouble3,
    },
    {
      room: "Small Double Standard Room",
      headImage: smallStandardDouble1,
      headDescription:
        "This room is best for our single travellers. It has a room size of 12m2 and is provided with everything a traveller needs for short stays but also for the longer stays it is suitable.",
      people: 2,
      bedrooms: 1,
      beds: "1 large Queen size bed 1,4m wide",
      bathroom: 1,
      coffee: "Nespresso Coffee",
      minibar: true,
      safe: true,
      ac: true,
      tv: "Smart TV with cable TV and streaming options",
      wifi: "Free and unlimited high speed WIFI",
      miniImage1: smallStandardDouble1,
      miniImage2: smallStandardDouble2,
      miniImage3: smallStandardDouble3,
    },
  ]);
  const [active, setActive] = useState(rooms[0]);
  const [page, setPage] = useState("Suite with Terrace");

  return (
    <div>
      <HeaderBlock
        text="Book your room in Rotterdam"
        img={headerImg}
      ></HeaderBlock>
      <ContentBlockMainPage
        locName="About Rotterdam"
        img={RotterdamImg}
        // eslint-disable-next-line no-sparse-arrays
        locDesc={[
          "Mr.Lewis hotel Rotterdam opened the doors since April 2022. The renovated hotel has a beautiful canal in front of the building. Which the high trees and the peaceful environment makes it a beautiful location to stay. The design rooms gives a feeling of comfort. The hotel is located in the city and has all public transport in just minutes away. The city centre is also just in walking distance. ",
          <br></br>,
          <br></br>,
          "Mr.Lewis hotel Rotterdam has 5 room types to discover. All rooms are equipped with airconditioning, a coffee machine, a teamaker, soap and a Smart TV. Our Superior King Suite is most suitable for longer stays. Our Junior Suites are most suitable for business travellers because of the large desk which comes with it. Our standard double rooms are most suitable for couples who come for recreation and city seeing. Then at last our Small Double Standard rooms are most suitable for single travellers.",
          <br></br>,
          <br></br>,
          "The city of Rotterdam has a lot to offer. With the Ahoy just 10 minutes from the hotel and the city centre 1 km away it is the perfect location to go to events and do some sightseeing. Rotterdam is a city of business and creativity. Also the 'Blijdorp Zoo' is just around the corner of the hotel.",
          <br></br>,
          <br></br>,
          <p className="underline">
            <strong> Parking</strong>
          </p>,
          "Parking is in front of the hotel. Everywhere in the city it is paid parking and the costs are €2.6,- per hour from 09:00 until 23:00. From 23:00 until 09:00 it is free. There is also in front of the hotel parking for electric cars.",
          <br></br>,
          <br></br>,
          <p className="underline">
            <strong> Check in</strong>
          </p>,
          "This is very important to know if you book with us! We are a completely self service hotel. We use the ‘JustIn mobile app’ which works like a traditional Keycard. Only now it is on your phone. Besides the online check in, this app is needed to open the doors with. We do not have a reception on site. So for questions or service, please contact us directly through our Whatsapp communication.",
          <br></br>,
          <br></br>,
          <p className="underline">
            <strong> Mr.Lewis Boutique Hotel</strong>
          </p>,
          "Heemraadssingel 90",
          <br></br>,
          "Rotterdam",
        ]}
        buttonText="CHECK OUT ROOMS"
        buttonLink="#"
        mirrored={false}
        bgColor="bg-baby-blue"
        button={false}
      ></ContentBlockMainPage>

      <div className="grid sm:grid-cols-3 mt-24">
        <div className="col-span-1 hidden md:block">
          {rooms.map((items) => {
            return (
              <div className="m-8 cursor-pointer" key={items.id}>
                <span
                  onClick={() => {
                    setActive(items);
                    setPage(items.room);
                  }}
                  className={
                    " " +
                    (items.room === page ? "text-darkgreen" : "text-gray-500")
                  }
                >
                  {items.room}
                </span>
              </div>
            );
          })}
        </div>

        <div className="sm:col-span-2 sm:grid sm:grid-cols-6 sm:ml-24 sm:mr-24 hidden md:block">
          <img
            src={active.headImage}
            alt=""
            className="col-span-6 object-cover text-center text-gray-200 w-full imageContent"
          ></img>
          <div className="col-span-4 mt-5">{active.headDescription}</div>
          <div className="col-span-2 mt-5">
            <ul>
              <li>- Accommodates {active.people} people</li>
              <li>- {active.beds} </li>
              <li>- 1 Ensuite Bathroom</li>
              <li>- Coffee Machine</li>
              <li>- Tea Maker</li>
              <li>- Climate controller</li>
              <li>- Smart TV</li>
              <li>- Free and unlimited high speed WIFI</li>
              <li>- Soap </li>
            </ul>
          </div>
          <div className="col-span-6 mt-20 mb-20 lg:text-2xl md:text-xl sm:text-base text-center ">
            {active.headliner}
          </div>
          <div className="col-span-3 m-5">
            <p>{active.extraDescription1} </p>
          </div>
          <div className="col-span-3 m-5">
            <p>{active.extraDescription2} </p>
          </div>
          <div className="col-span-6 grid grid-cols-2">
            <div className="col-span-1 m-5">
              <img className="w-full " src={active.miniImage2} alt=""></img>
            </div>
            <div className="col-span-1 m-5">
              <img className="w-full " src={active.miniImage3} alt=""></img>
            </div>
            {/* <img src={active.headImage} alt="" className=" w-full m-5"></img> */}
          </div>
        </div>
        {/* <button className="distributor-open">Book Now</button> */}
        {rooms.map((items) => {
          return (
            <div className="col-span-2 sm:grid sm:grid-cols-6 sm:ml-24 sm:mr-24 p-5  md:hidden">
              <h1 className="text-3xl darkgreen m-10"> {items.room} </h1>
              <img
                src={items.headImage}
                alt=""
                className="sm:col-span-6 object-cover text-center text-gray-200 w-full imageContent"
              ></img>
              <div className="sm:col-span-4 mt-5">{items.headDescription}</div>
              <div className="col-span-2 mt-5">
                <div className="flex">
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2"> {items.people} PEOPLE </p>
                  </div>
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2"> {items.bedrooms} BEDROOMS </p>
                  </div>
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2">
                      {" "}
                      {items.beds.double} DOUBLE, {items.beds.single} SINGLE{" "}, {items.beds.king} KING{" "}
                    </p>
                  </div>
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2"> {items.bathroom} BATHROOM</p>
                  </div>
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2"> BICYLES </p>
                  </div>
                  <div className="flex p-2">
                    <img src={iconInfo} alt=""></img>
                    <p className="m-2"> {items.extra} </p>
                  </div>
                </div>
              </div>
              <div className="col-span-6 mt-20 mb-20 lg:text-2xl md:text-xl sm:text-base text-center ">
                {items.headliner}
              </div>
              <div className="col-span-3 m-5">
                <p>{items.extraDescription1} </p>
              </div>
              <div className="col-span-3 m-5">
                <p>{items.extraDescription2} </p>
              </div>
              <div className="col-span-6 grid grid-cols-2">
                <div className="col-span-1 m-5">
                  <img className="w-full " src={items.miniImage2} alt=""></img>
                </div>
                <div className="col-span-1 m-5">
                  <img className="w-full " src={items.miniImage3} alt=""></img>
                </div>
                {/* <img src={active.headImage} alt="" className=" w-full m-5"></img> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Home;
