/* eslint-disable import/first */

import React from "react";
import ReactGA from 'react-ga4';
import { HashRouter, Route, Switch } from "react-router-dom";

ReactGA.initialize('G-S2F6L4DTSL');

import About from "./Views/About";
import ContactUs from "./Views/ContactUs";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Views/Home";
import Overveen from "./Views/Overveen";
import Product from "./Views/Products";
import Rotterdam from "./Views/Rotterdam"
import { usePageTracking } from './Hooks/usePageTracking';
import { ScrollToTop } from './Hooks/ScrollToTop';

const GATrackingWrapper = () => {
  usePageTracking();
  ScrollToTop();

  return (
    <>
      <Header />
      <div className="p-0">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          {/* <Route path="/overveen">
            <Overveen />
          </Route> */}
          <Route path="/rotterdam">
            <Rotterdam />
          </Route>
        </Switch>
        <Route path="/products/:id">
          <Product />
        </Route>
      </div>
      <Footer />
    </>
  )
}

const App = () => {


  return (
    <div className="relative pb-10 min-h-screen">
      <HashRouter>
        <GATrackingWrapper />
      </HashRouter>
    </div>
  );
}

export default App;
