import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { useTransition, animated } from "react-spring";
import NavigationMenu from "./NavigationMenu";

function Navigation() {
  const [showMenu, setShowMenu] = useState(false);

  // window.scrollTo({ top: 0, behavior: "smooth" });

  const navcontent = useRef();

  const masktransitions = useTransition(showMenu, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const menutransitions = useTransition(showMenu, null, {
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" },
  });

  return (
    <nav id="header">
      <span className="text-2xl text-black">
        <FontAwesomeIcon
          icon={faGripLines}
          onClick={() => setShowMenu(!showMenu)}
        />{" "}
      </span>
      {masktransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="bg-black-t-50 fixed top-0 left-0 w-full h-full z-50"
              onClick={() => setShowMenu(false)}
            ></animated.div>
          )
      )}
      {menutransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="fixed bg-white top-0 left-0 w-full h-full z-50 shadow p-3"
            >
              <div ref={navcontent} id="nav-content">
                <NavigationMenu closeMenu={() => setShowMenu(false)} />
              </div>
            </animated.div>
          )
      )}
    </nav>
  );
}

export default Navigation;
