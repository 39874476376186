import React from "react";
import view1 from "../img/view1.jpg";
import view2 from "../img/view2.jpg";
import view3 from "../img/view3.jpg";

function ContactUs() {
  return (
    <div>
      <div className="grid grid-cols-3 my-40 mx-24">
        <div className="col-span-3 md:col-span-1">
          <h1 className="text-darkgreen tracking-widest mb-6 text-4xl">
            {" "}
            Contact{" "}
          </h1>
          <ul>
            <li className="text-darkgreen mt-5 py-2 text-xs tracking-widest">
              <a href="https://www.facebook.com/mr.lewishotel/?view_public_for=561960147565099">
                {" "}
                Facebook{" "}
              </a>
            </li>
            <li className="text-darkgreen py-2 text-xs tracking-widest">
              <a href="https://www.instagram.com/mr.lewishotel/"> Instagram </a>
            </li>
            <li className="text-darkgreen py-2 text-xs tracking-widest">
              {/* <a href=""> Twitter </a> */}
            </li>
          </ul>
        </div>
        <div className="col-span-3 md:col-span-1">
          <h1 className="text-darkgreen tracking-widest mb-6 text-4xl">
            {" "}
            Rotterdam{" "}
          </h1>
          <ul>
            <li className="text-darkgreen py-2 text-xs tracking-widest">
              T: +31 (0) 10 310 51 53
            </li>
            <li className="text-darkgreen py-2 text-xs tracking-widest">
              M: stay@mrlewis.n
            </li>
            <li className="text-darkgreen py-2 text-xs tracking-widest">
              Upon arrival, we require a deposit of € 100,- for any extras during your stay. This will be collected by credit card. If not used, it will be refunded in full to your credit card within 7 days of your departure.
            </li>
          </ul>
        </div>
      </div>
      <div className="flex">
        <img className="w-1/3" src={view1} alt=""></img>
        <img className="w-1/3" src={view2} alt=""></img>
        <img className="w-1/3" src={view3} alt=""></img>
      </div>
    </div>
  );
}
export default ContactUs;
