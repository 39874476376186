import React from "react";

function HelloWorld(props) {
  return (
    <div className="lg:text-2xl md:text-xl sm:text-base w-1/2 ml-auto mr-auto text-center py-20">
      {props.text}
    </div>
  );
}

export default HelloWorld;
