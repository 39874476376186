import React, { useState } from 'react'
import Cookies from 'js-cookie'
import crossImage from './../img/close_small.svg'

const Notification = () => {

    const [isShow, setIsShow] = useState(Cookies.get('mrlewis-notification-show'))

    const handleCloseClick = () => {
        Cookies.set('mrlewis-notification-show', "false", {expires: 1})
        setIsShow("false")
    }
    if (isShow === "false") {
      return null
    }
    return (
     <div className={`w-full py-4 bg-white -translate-y-full transition-opacity duration-300`}>
          <div className="container mx-auto flex flex-row justify-between items-center gap-x-2 text-darkgreen">
              <p className='w-full text-center px-2 font-bold md:text-xl max-md:text-lg leading-[30px]'>Use the discount code Summer2024 for the best price</p>
              <button className='w-12 h-12 flex items-center justify-end' onClick={handleCloseClick}>
                <img src={crossImage} alt="" />
              </button>
          </div>
      </div>
      
    )
}

export default Notification