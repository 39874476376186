import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// ReactGA.initialize('G-S2F6L4DTSL');

export const usePageTracking = () => {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname });
    }, [location]);
};
