import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

function ContentBlockMainPage(props) {
  const BlockClass = classNames({
    "md:w-2/5": props.mirrored,
    "md:w-3/5": !props.mirrored,
    [props.bgColor]: true,
  });
  let buttonContent = null;
  let linkContent = null;
  if (props.button === true) {
    buttonContent = (
      <Link to={props.buttonLink} onClick={props.closeMenu}>
        <button className="bg-transparent mt-10 hover:bg-black text-black font-semibold hover:text-white py-4 px-8 border border-black hover:border-transparent rounded p-10">
          {props.buttonText}
        </button>
      </Link>
    );
  }
  if (props.link === true) {
    linkContent = (
      <h1 className="text-2xl darkgreen">
        &nbsp;
        <a href="www.dabstudio.nl" className="underline">
          {props.locName}
        </a>
      </h1>
    );
  } else {
    linkContent = (
      <span className="border-b-2 border-black">
        <h1 className="text-2xl darkgreen pl-12 pr-2">
          {" "}
          &nbsp; {props.locName}{" "}
        </h1>
      </span>
    );
  }
  return (
    <div
      className={"flex flex-wrap " + (props.mirrored ? "flex-row-reverse " : " ") + props.bgColor}
    >
      <img
        src={props.img}
        className={
          "object-cover text-center text-gray-200 " +
          (props.mirrored ? "md:w-3/5" : "md:w-2/5")
        }
        alt=""
      ></img>
      <div className={BlockClass}>
        <div className="pt-12 pb-12 md:pt-6 md:pb-6 sm:pl-16 m-5">
          <div className="flex mb-4">
            <div className="w-full  flex">{linkContent}</div>
          </div>
          {/* <div className="flex mb-4"> */}
          <div
            className={
              "flex mb-4 " + (props.mirrored ? "sm:mr-16" : "sm:mr-16")
            }
          >
            <div className="w-full sm:max-w-sm">
              <div>{props.locDesc}</div>
              {buttonContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentBlockMainPage;
