import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../Components/Loader";
import { useAxiosGet } from "../Hooks/HttpRequests";

function Product() {
  const { id } = useParams();
  const url = `https://5fa538db732de900162e8e61.mockapi.io/api/v1/locations/1/rooms/${id}`;

  let product = useAxiosGet(url);

  let content = null;

  if (product.loading) {
    content = <Loader> </Loader>;
  }
  if (product.error) {
    content = (
      <div>
        <div className="bg-red-300 p-3">
          There was an error please refresh or try again later.
        </div>
      </div>
    );
  }
  if (product.data) {
    content = (
      <div>
        <h1 className="text-2xl font-bold mb-3">{product.data.roomName}</h1>
        <div>
          <img src={product.data.headImage} alt={product.data.roomName} />
        </div>
        <div className="font-bold text-xl mb-3">€ {product.data.roomPrice}</div>
        <div>{product.data.roomDescription}</div>
      </div>
    );
  }

  return <div>{content}</div>;
}

export default Product;
